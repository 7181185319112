.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
}

.nav-left, .nav-right, .nav-center {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.nav-left {
  justify-content: flex-start;
}

.nav-center {
  justify-content: center;
}

.nav-right {
  justify-content: flex-end;
}

.nav-button {
  background-color: transparent;
  border: 1px solid var(--dark-text);
  color: var(--dark-text);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--dark-text);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.9rem;
  border: 1px solid var(--border-color);
  cursor: default;
  user-select: none;
}

.logout-button {
  background-color: transparent;
  border: 1px solid var(--dark-text);
  color: var(--dark-text);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
