.content {
    flex: 1;
    padding: 1rem;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    overflow-x: hidden;
  }
  
  .url-input-container {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .url-input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    font-size: 0.9rem;
    min-width: 200px;
    height: 38px;
  }
  
  .content .load-button {
    background-color: var(--primary-color);
    color: var(--dark-text);
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-size: 0.9rem;
    height: 38px;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
  }
  
  .content .load-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--accent-color);
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .content .load-button:active {
    background-color: var(--primary-color);
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .trending-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 24px;  /* Increased gap between cards */
    padding: 24px;  /* Added padding around the grid */
  }
  
  .manga-card {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: var(--shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    position: relative;  /* For gradient overlay */
    overflow: hidden;    /* Contain the gradient */
  }
  
  /* Gradient overlay effect */
  .manga-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, var(--accent-color) 0%, transparent 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  /* Hover animations */
  .manga-card:hover {
    transform: translateY(-4px) scale(1.02);
    border-color: var(--accent-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .manga-card:hover::before {
    opacity: 0.1;
  }
  
  .manga-card:hover .manga-icon {
    transform: rotate(5deg) scale(1.1);
    color: var(--dark-text);
  }
  
  .manga-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 1;  /* Keep content above gradient */
    width: 100%;
    padding: 8px;
  }
  
  .manga-title {
    color: var(--dark-text);
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    text-align: center;
    line-height: 1.4;
    flex: 0 1 auto;
    transition: transform 0.3s ease;
  }
  
  .manga-icon {
    color: var(--accent-color);
    font-size: 16px;
    min-width: 16px;
    flex-shrink: 0;
    transition: all 0.3s ease;
  }
  
  .start-reading-button {
    display: inline-block;
    background-color: var(--primary-color);
    color: var(--dark-text);
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
    border: 1px solid var(--border-color);
  }
  
  .start-reading-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--accent-color);
  }
  
  .error-message {
    color: #e74c3c;
    background-color: #fadbd8;
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
  }
  
  .dropdowns-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .dropdown-wrapper {
    position: relative;
    width: 100%;
  }
  
  .history-dropdown-button {
    background-color: var(--card-background);
    color: var(--dark-text);  /* Ensure dropdown text is white */
    border: 1px solid var(--border-color);
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .history-dropdown-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--accent-color);
  }
  
  .history-dropdown-button .icon {
    margin-right: 8px;
    color: var(--primary-color);
  }
  
  .reading-history-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--card-background);
    border: 1px solid var(--primary-color);
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: var(--shadow);
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .history-item {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: var(--dark-text);  /* Ensure history item text is white */
    border-bottom: 1px solid var(--border-color);
    transition: background-color 0.3s ease;
  }
  
  .history-item:last-child {
    border-bottom: none;
  }
  
  .history-item:hover {
    background-color: rgba(114, 137, 218, 0.1);
  }
  
  .history-item .manga-title {
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .history-item .chapter-info {
    color: var(--text-color);  /* Lighter gray for secondary text */
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .url-input-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .url-input,
    .load-button {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  
    .url-input {
      margin-right: 0;
    }
  
    .load-button {
      height: 44px;
      font-size: 1rem;
    }
  
    .trending-container {
      grid-template-columns: 1fr;
    }
  
    .manga-card {
      margin-bottom: 1rem;
    }
  
    .dropdowns-container {
      flex-direction: column;
    }
  
    .dropdown-wrapper {
      width: 100%;
    }
  
    .history-dropdown-button,
    .history-item {
      padding: 16px;
    }
  }
  
  @media (min-width: 768px) {
    .dropdowns-container {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .dropdown-wrapper {
      width: 48%;
    }
  }

  .homepage-load-button {
    background-color: var(--primary-color);
    color: var(--dark-text);
    padding: 8px 16px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-weight: 500;
    letter-spacing: 0.3px;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-size: 0.9rem;
    height: 38px;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .homepage-load-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--accent-color);
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .homepage-load-button:active {
    background-color: var(--primary-color);
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .search-form {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  .search-input {
    flex-grow: 1;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-right: none;
    border-radius: 4px 0 0 4px;
    font-size: 0.9rem;
    min-width: 200px;
    height: 40px;
    background-color: var(--card-background);
    color: var(--dark-text);
  }

  .search-input::placeholder {
    color: rgba(228, 230, 235, 0.5);
  }

  .search-button {
    background-color: var(--primary-color);
    color: var(--dark-text);
    padding: 8px 16px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--accent-color);
  }

  @media (max-width: 768px) {
    .search-form {
      flex-direction: column;
      align-items: stretch;
    }

    .search-input {
      border-right: 1px solid var(--border-color);
      border-radius: 4px;
      margin-bottom: 0.5rem;
    }

    .search-button {
      border-radius: 4px;
    }
  }

  .content .load-button,
  .homepage-load-button,
  .search-button {
    background-color: var(--primary-color);
    color: var(--dark-text);
    border: 1px solid var(--border-color);
  }

  .content .load-button:hover,
  .homepage-load-button:hover,
  .search-button:hover {
    background-color: var(--secondary-color);
    border-color: var(--accent-color);
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .manga-card {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    box-shadow: var(--shadow);
  }

  .history-dropdown-button {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
  }

  .search-input {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
  }
