.legal-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
}

.legal-nav {
  padding: 1rem 2rem;
  background-color: var(--card-background);
  border-bottom: 1px solid var(--border-color);
}

.home-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--dark-text);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.home-link:hover {
  opacity: 0.8;
}

.legal-page {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  text-align: left; /* Ensure base alignment is left */
}

.legal-container {
  background-color: var(--card-background);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: var(--shadow);
  margin-bottom: 2rem;
}

.legal-page h1, 
.legal-page h2, 
.legal-page h3,
.legal-page p,
.legal-page ul,
.legal-page li {
  text-align: left;
  margin-left: 0;
  padding-left: 0;
}

.legal-page ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.legal-page li {
  text-align: left;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
  line-height: 1.8;
}

/* Main bullet points */
.legal-page li::before {
  content: "•";
  margin-right: 1rem;
  min-width: 1em;
}

/* Nested list container */
.legal-page li ul {
  margin: 0;
  padding-left: 1em; /* Align with parent text start */
}

/* Nested list items */
.legal-page li ul li {
  margin: 0;
  padding: 0;
}

.legal-page li ul li::before {
  content: "•";
  margin-right: 1rem;
}

/* Adjust nested list indentation */
.legal-page ul ul {
  margin: 0.8rem 0 0 2rem; /* Adjust the left margin for nested list */
}

/* Remove the bullet for nested items if they're under a specific parent */
.legal-page li li::before {
  margin-left: 0.5rem; /* Slight indent for nested bullets */
}

.last-updated {
  color: var(--secondary-text);
  font-style: italic;
  margin: 1rem 0 2rem;
  text-align: left;
}

.section-divider {
  border-top: 1px solid var(--border-color);
  margin: 2rem 0;
}

/* Additional sections styling */
.legal-page .important-notice {
  background-color: rgba(var(--accent-color-rgb), 0.1);
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
}

.legal-page .contact-section {
  text-align: left;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 2px solid var(--border-color);
}

.legal-page .contact-section a {
  color: var(--dark-text);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.legal-page .contact-section a:hover {
  opacity: 0.8;
}

.legal-page a {
  color: var(--dark-text);
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.legal-page a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .legal-page {
    padding: 1rem;
  }
  
  .legal-container {
    padding: 1.5rem;
  }

  .legal-page h1 {
    font-size: 2rem;
  }

  .legal-page h2 {
    font-size: 1.5rem;
  }
}
