* {
  box-sizing: border-box;
}

body, html {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #2f3136;        /* Dark gray for cards/containers */
  --secondary-color: #36393f;      /* Slightly lighter gray for hover states */
  --background-color: #141517;     /* Very dark background */
  --text-color: #dcddde;          /* Light gray text */
  --header-color: #0f1012;        /* Darker header */
  --card-background: #18191c;     /* Slightly lighter than background for cards */
  --accent-color: #4f545c;        /* Subtle accent for borders and highlights */
  --shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  --dark-text: #ffffff;           /* Pure white for important text */
  --border-color: rgba(255, 255, 255, 0.1); /* Subtle border color */
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.App-header {
  background-color: var(--header-color);
  padding: 1rem;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.app-title {
  margin: 0;
  font-size: 1.5rem;
  position: absolute;
  left: 1rem;
}

.main-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav-left {
  flex: 1;
}

.nav-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.home-button {
  background-color: transparent;
  border: 1px solid var(--dark-text);
  color: var(--dark-text);
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline-block;
}

.home-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-link {
  color: var(--dark-text);
  text-decoration: none;
  margin-right: 1rem;
  opacity: 0.9;
}

.nav-link:hover {
  opacity: 1;
}

.user-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-greeting {
  margin-right: 1rem;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--dark-text);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.9rem;
  border: 1px solid var(--border-color);
  cursor: default;
  user-select: none;
}

.logout-button {
  background-color: transparent;
  border: 1px solid var(--dark-text);
  color: var(--dark-text);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.error-message {
  color: #f04747;
  background-color: rgba(240, 71, 71, 0.1);
  border: 1px solid rgba(240, 71, 71, 0.3);
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loading-spinner {
  border: 3px solid var(--primary-color);
  border-left-color: var(--accent-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .app-title {
    font-size: 1.2rem;
  }

  .home-button {
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
  }

  .App-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }

  .main-nav {
    display: flex;
    align-items: center;
  }

  .user-controls {
    margin-top: 0;
  }

  .nav-link {
    font-size: 0.9rem;
    margin-right: 0.5rem;
  }

  .logout-button {
    padding: 0.3rem 0.7rem;
    font-size: 0.8rem;
  }

  .user-avatar {
    width: 28px;
    height: 28px;
    font-size: 0.8rem;
  }
}

.app-logo {
  height: 45px;  /* Increased size further */
  width: auto;
  position: absolute;
  left: 1rem;
}

@media (max-width: 768px) {
  .app-logo {
    height: 35px;  /* Adjusted mobile size */
  }
}

/* Add these new styles at the end of the file */
.content {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.manga-images-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.manga-image {
  width: 100%;
  height: auto;
  display: block;
}

.view-page {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

button {
  color: var(--dark-text);
}

/* Add at the end of the file */
.App-footer {
  background-color: var(--header-color);
  padding: 1rem;
  margin-top: auto;
  border-top: 1px solid var(--border-color);
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.footer-link {
  color: var(--dark-text);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--accent-color);
}

.login-button {
  background-color: transparent;
  border: 1px solid var(--dark-text);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
