.view-page {
    background-color: var(--dark-background);
    color: var(--dark-text);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
}
  
.view-page .chapter-header {
    background-color: var(--header-color);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
  
.view-page .chapter-title-container {
    flex-grow: 1;
    text-align: center;
}
  
.view-page .chapter-title {
    color: var(--dark-text);
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
}
  
.view-page .chapter-actions {
    display: flex;
    gap: 0.5rem;
}
  
.view-page .home-button {
    color: var(--dark-text);
    text-decoration: none;
    margin-right: 1rem;
}
  
.view-page .content {
    padding: 0;
    flex-grow: 1;
    width: 100%;
    overflow-x: hidden;
}
  
.view-page .manga-image {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}
  
.manga-images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
}
  
.manga-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}
  
.manga-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
}
  
.error-message {
    color: #e74c3c;
    background-color: #fadbd8;
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
}
  
.bookmark-button,
.share-button {
    background: none;
    border: none;
    color: var(--dark-text);
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.bookmark-button:hover,
.share-button:hover {
    opacity: 0.7;
}
  
.bookmark-button {
    background-color: transparent;
    color: var(--dark-text);
}
  
.bookmark-button.bookmarked {
    color: var(--accent-color);
}
  
.share-button svg,
.bookmark-button svg {
    width: 20px;
    height: 20px;
}
  
.chapter-navigation,
.bottom-navigation {
  background-color: var(--header-color);
  border-bottom: 1px solid var(--accent-color);
}

.chapter-navigation .nav-button,
.bottom-navigation .nav-button {
  background-color: var(--primary-color);
  border: 1px solid var(--accent-color);
}

.chapter-navigation .nav-button:hover,
.bottom-navigation .nav-button:hover {
  background-color: var(--secondary-color);
  border-color: var(--text-color);
}

.chapter-navigation .chapter-dropdown {
  background-color: var(--card-background);
  border: 1px solid var(--accent-color);
}

.chapter-navigation .nav-button:disabled,
.bottom-navigation .nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
  
.chapter-navigation .button-icon,
.bottom-navigation .button-icon {
    font-size: 1.2rem;
}
  
.chapter-navigation .chapter-dropdown {
    flex-grow: 1;
    max-width: 50%;
    padding: 0.5rem;
    font-size: 0.9rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    background-color: white;
    margin: 0 0.5rem;
}
  
@media (max-width: 768px) {
    .chapter-title {
      font-size: 1rem;
    }
  
    .chapter-navigation .button-text,
    .bottom-navigation .button-text {
      display: none;
    }
  
    .chapter-navigation .nav-button,
    .bottom-navigation .nav-button {
      padding: 0.5rem;
    }
  
    .chapter-navigation .chapter-dropdown {
      max-width: 60%;
    }
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background-color: var(--header-color);
  padding: 5px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  transition: opacity 0.1s ease-in-out;
}

.bottom-navigation .nav-button {
  flex: 1;
  margin: 0 5px;
  padding: 8px 5px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-navigation .nav-button .button-icon,
.bottom-navigation .nav-button .button-text {
  display: inline-flex;
  align-items: center;
}

.bottom-navigation .nav-button .button-icon {
  margin-right: 5px;
}

/* Adjust the main content to prevent overlap with the fixed navigation */
.view-page .content {
  padding-bottom: 60px; /* Increased padding to account for the bottom navigation */
}

/* Update chapter navigation */
.chapter-navigation {
  background-color: var(--header-color);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the navigation items */
  gap: 16px;
  border-bottom: 1px solid var(--border-color);
}

.chapter-navigation .nav-button,
.bottom-navigation .nav-button {
  background-color: var(--primary-color);
  color: var(--dark-text);
  border: 1px solid var(--border-color);
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  min-width: 100px; /* Increased min-width for better button sizing */
  height: 36px; /* Fixed height for consistency */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between icon and text */
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.chapter-navigation .nav-button:hover,
.bottom-navigation .nav-button:hover {
  background-color: var(--secondary-color);
  border-color: var(--accent-color);
}

.chapter-navigation .chapter-dropdown {
  background-color: var(--card-background);
  color: var(--dark-text);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  height: 36px; /* Match button height */
  width: 200px; /* Fixed width for better layout */
  cursor: pointer;
}

/* Style for the button icons and text */
.button-icon {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.button-text {
  font-weight: 500; /* Medium weight for better readability */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Disabled button states */
.chapter-navigation .nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--primary-color);
  border-color: var(--border-color);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .chapter-navigation {
    padding: 8px;
    gap: 8px;
  }

  .chapter-navigation .nav-button {
    min-width: 40px;
    padding: 8px;
  }

  .button-text {
    display: none; /* Hide text on mobile */
  }

  .chapter-navigation .chapter-dropdown {
    width: auto;
    flex: 1;
  }
}
