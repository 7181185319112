.site-footer {
  background-color: var(--card-background);
  border-top: 1px solid var(--border-color);
  padding: 1.5rem 0;
  margin-top: auto;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
  margin-bottom: 0.5rem;
}

.footer-link {
  color: var(--dark-text);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--accent-color);
}

.footer-contact {
  margin-bottom: 0.5rem;
}

.footer-copyright {
  color: var(--secondary-text);
  font-size: 0.8rem;
  text-align: center;
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
