.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px); /* Adjust based on your header height */
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.login-container {
  max-width: 600px;
  width: 100%;
  padding: 2rem;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-container h1 {
  margin-bottom: 1.5rem;
  color: var(--primary-color);
  font-size: 2rem;
}

.login-description {
  margin-bottom: 2rem;
  text-align: center;
}

.login-description p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.login-description ul {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  text-align: left;
}

.login-description li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.login-description li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--dark-text);
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.google-login-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.google-login-button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.google-icon {
  font-size: 1.2rem;
}

.error-message {
  color: var(--error-color);
  background-color: var(--error-background);
  border: 1px solid var(--error-border);
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
}
